/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'

import Icon from './Icon'

interface ModalProps {
  show: boolean
  allowCloseByButton?: boolean
  blockModalClose?: boolean
  onHide: () => void
  children: any
  title?: string
  testId?: string
  modalType?: 'warning' | null
  modalSize?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '4xl' | '6xl'
}

export default function Modal(props: ModalProps) {
  const [open, setOpen] = useState(!!props.show)
  const allowCloseByButton = props.allowCloseByButton || false
  const blockModalClose = props.blockModalClose || false

  const modalSize = props.modalSize || 'sm'
  const testId = props.testId || ''

  React.useEffect(() => {
    setOpen(!!props.show)
  }, [props.show])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          if (!blockModalClose) {
            props.onHide && props.onHide()
            setOpen(false)
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary-600 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-start justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${
                  props.modalType === 'warning'
                    ? 'border border-4 border-warning'
                    : ''
                } relative bg-white rounded px-4 pt-5 pb-4 text-left overflow-show shadow-xl transform transition-all my-24 sm:max-w-${modalSize} sm:w-full sm:p-6`}
                data-testid={`${testId}Modal`}
              >
                <div
                  className="text-center"
                  data-testid={`${testId}ModalCloseButton`}
                >
                  {(!blockModalClose || allowCloseByButton) && (
                    <Icon
                      onClick={() => {
                        props.onHide && props.onHide()
                        setOpen(false)
                      }}
                      className="float-right cursor-pointer"
                      type="close"
                    />
                  )}
                  {props.title && <h1 className="font-bold">{props.title}</h1>}
                </div>

                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
