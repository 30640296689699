import { AuthContext } from '@lib/util/fe/dataContext/authenticationContext'
import React, { useContext, useEffect } from 'react'

import Campaigns from '~/components/pages/Campaigns'
import AcceptTermsModal from '~/components/shared/modals/AcceptTermsModal'
import AuthorizedAccess from '~/components/shared/template/guards/AuthorizedAccess'
import Layout from '~/components/shared/template/Layout'
import PageFullContainer from '~/components/shared/template/PageFullContainer'
import TemplateHeader from '~/components/shared/template/TemplateHeader'

const CampaignsPage = (props) => {
  const {
    authorizedValues: { user: currentUser, initialized },
    protectPage
  } = useContext(AuthContext)

  useEffect(() => {
    if (initialized && protectPage) {
      protectPage(initialized, currentUser)
    }
  }, [initialized, protectPage])

  return (
    <Layout title="Your Campaigns">
      <TemplateHeader activePage="campaigns" hideMenuButton />

      <AuthorizedAccess requireCampaign={false}>
        <AcceptTermsModal currentUser={currentUser} />
        <PageFullContainer>
          <Campaigns currentUser={currentUser} />
        </PageFullContainer>
      </AuthorizedAccess>
    </Layout>
  )
}

export default CampaignsPage
