import * as React from 'react'

import ButtonBase from '../ui/ButtonBase'
import Modal from '../ui/Modal'

// Change when terms update to trigger modal
const termsLastUpdated = new Date('12 14 2021')

const AcceptTermsModal = (props) => {
  let [show, setShow] = React.useState(false)
  let { currentUser } = props
  React.useEffect(() => {
    setShow(
      !!currentUser &&
        (!currentUser.last_accepted_terms ||
          new Date(currentUser.last_accepted_terms) < termsLastUpdated)
    )
  }, [props.currentUser])

  const handleAccept = async () => {
    let url = '/api/accept-terms'
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    window.location.href = `/campaigns` // reload with current user
  }

  return (
    <Modal
      show={show}
      onHide={() => console.warn('hidden')}
      blockModalClose={true}
      testId="acceptTerms"
    >
      <div className="modalContent">
        <div>
          <div>
            <h1 className="text-center mb-12">Heads up!</h1>
          </div>
        </div>
        <div>
          <p>
            We've updated our{' '}
            <a href="https://www.strivemessaging.org/terms/" target="__blank">
              Terms
            </a>{' '}
            and{' '}
            <a href="https://www.strivemessaging.org/privacy/" target="__blank">
              Privacy Policy
            </a>
            .
          </p>
          <p className="mt-4">
            Please read and accept these changes before continuing.
          </p>
          <ButtonBase
            className="mt-4"
            onClick={handleAccept}
            testId="modalAcceptTermsAgree"
          >
            Agree
          </ButtonBase>
        </div>
      </div>
    </Modal>
  )
}
export default AcceptTermsModal
