import React from 'react'

function formatPhoneNumber(untrimmedPhone: string) {
  let phone = untrimmedPhone.trim()
  let isShortcode = phone.match(/^\d{5,6}$/)
  if (isShortcode) return phone.toString()
  let res = phone.match(/^\+1(\d{3})(\d{3})(\d{4})$/)
  if (res) {
    let [_, area, mid, last] = res
    return '(' + area + ') ' + mid + '-' + last
  }
  let aus = phone.match(/^\+61(\d{3})(\d{3})(\d{3})$/)
  if (aus) {
    let [_, area, mid, last] = aus
    return '+61 ' + area + ' ' + mid + ' ' + last
  }
}

export type FormatPhoneProps = {
  phoneNumber: string
}
const FormatPhone = ({ phoneNumber }: FormatPhoneProps) => (
  <span className="formattedPhone">
    {phoneNumber && formatPhoneNumber(phoneNumber)}
  </span>
)

export default FormatPhone
