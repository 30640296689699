import 'isomorphic-fetch'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import type { CampaignLink, CampaignMinimalFetch } from '~/types/campaigns'
import type { CurrentUser } from '~/types/users'

import fetch from '../../lib/util/fe/fetch'
import FormatPhone from '../shared/Formatters/FormatPhone'
import PageHeader from '../shared/template/PageHeader'

type CampaignsPageContentProps = {
  currentUser: CurrentUser
}

const CampaignsPageContent = ({ currentUser }: CampaignsPageContentProps) => {
  const [campaigns, setCampaigns] = useState<CampaignMinimalFetch[]>([])
  const [initialLoad, setInitialLoad] = useState(true)

  const router = useRouter()

  const onInitialLoad = async () => {
    const response = await fetch('/api/campaigns')
    if (response.status === 200) {
      const data = await response.json()

      if (data.length === 0) {
        router.push({ pathname: '/subscription-plans' })
      } else {
        setCampaigns(data)
      }
    }
  }
  useEffect(() => {
    if (initialLoad) {
      onInitialLoad()
      setInitialLoad(false)
    }
  }, [])

  const campaignCardClass = `w-full relative cursor-pointer border border-primary-300 bg-white h-[200px] p-7 text-center shadow hover:border-active hover:-translate-y-0.5 hover:transition-all hover:shadow-lg`
  const campaignCardHeaderClass = `-mt-6 -ml-6 -mr-6 px-6 py-5 relative after:content-[' '] after:w-[10px] after:absolute after:bg-primary-300 after:left-24 after:right-24 after:bottom-0 after:h-[1px] after:w-[50%] after:mx-auto`
  const createCampaignCardHeaderClass = `${campaignCardHeaderClass} pb-1 after:bg-transparent`

  return (
    <>
      {!initialLoad && campaigns.length > 0 && (
        <>
          <PageHeader title="Your Campaigns" testId="dashboard" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {campaigns.map(({ name, phoneNumber, createdAt, id }) => {
              let campaignLink: CampaignLink = { as: null, href: null }
              if (currentUser.isAdmin) {
                campaignLink.as = `/campaigns/${id}`
                campaignLink.href = '/campaigns/[campaignId]'
              } else {
                campaignLink.as = `/campaigns/${id}/inbox`
                campaignLink.href = '/campaigns/[campaignId]/inbox'
              }
              return (
                <div key={id} data-testid={`campaignCardLink-${id}`}>
                  <Link
                    legacyBehavior={true}
                    as={campaignLink.as}
                    href={campaignLink.href}
                  >
                    <div
                      className={campaignCardClass}
                      data-testid="campaignCard"
                    >
                      <div
                        className={campaignCardHeaderClass}
                        data-testid="campaignCardHeader"
                      >
                        <div className="text-lg" data-testid="campaignCardName">
                          {name}
                        </div>
                      </div>
                      <div className="pt-5">
                        <div
                          className="relative mb-5"
                          data-testid="campaignCardPhoneNumber"
                        >
                          <FormatPhone phoneNumber={phoneNumber} />
                        </div>
                        <div
                          className="text-sm text-primary-600 italic"
                          data-testid="campaignCardCreatedAt"
                        >
                          Created: {new Date(createdAt).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}

            {currentUser.isAdmin && (
              <div>
                <Link legacyBehavior={true} href="/subscription-plans">
                  <div
                    className={campaignCardClass}
                    data-testid="createCampaignCard"
                  >
                    <div data-testid="createCampaignButton">
                      <div
                        className={createCampaignCardHeaderClass}
                        data-testid="createCampaignCardHeader"
                      >
                        <div className="text-lg">Create a New Campaign</div>
                      </div>
                      <Image
                        className="mx-auto max-h-[50px] mt-5 pl-1.5"
                        src="/static/images/sms_icon.svg"
                        alt="graphical phone sending sms"
                        height="50"
                        width="50"
                        data-testid="createCampaignCardImage"
                      />
                      <span
                        className="block h-[50px] w-[50px] absolute left-[calc(50%-25px)] bottom-[-25px] bg-primary text-white border rounded-full text-2xl leading-[50px]"
                        data-testid="createCampaignCardButton"
                      >
                        +
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default CampaignsPageContent
