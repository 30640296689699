import { ReactNode } from 'react'

/**
 * Component to be used for pages to make the displayed page title and subtitle consistent.
 */
export type PageHeaderProps = {
  children?: ReactNode
  subtitle?: string
  title: string
  testId?: string
}

const PageHeader = ({
  children,
  subtitle,
  title,
  testId = ''
}: PageHeaderProps) => {
  const hasSubtitle = !!subtitle
  return (
    <div className="max-w-prose mb-6" data-testid={`${testId}Header`}>
      <h1 className="font-semibold text-2xl">{title}</h1>
      {hasSubtitle && <p>{subtitle}</p>}
      {children}
    </div>
  )
}

export default PageHeader
